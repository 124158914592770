import React from 'react';
import { Router } from '@reach/router';
import { connect } from 'react-redux';

import { loader } from './app.module.scss';
import useHasMounted from '../hooks/useHasMounted';

import { ROUTES, renderRoutes } from '../routes';
import Loader from '../components/atoms/loader';

const routesMap = renderRoutes(ROUTES);

const App = ({ isUserVerificationFinished }) => {
    const hasMounted = useHasMounted();

    if (!hasMounted || !isUserVerificationFinished) {
        return <Loader className={loader} />;
    } else {
        return <Router>{routesMap}</Router>;
    }
};

export default connect(
    (state) => ({
        isUserVerificationFinished: state.user.isUserVerificationFinished,
    }),
    null
)(App);
